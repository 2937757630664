// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-documentation-export-import-js": () => import("/opt/build/repo/src/pages/documentation/export-import.js" /* webpackChunkName: "component---src-pages-documentation-export-import-js" */),
  "component---src-pages-documentation-extensions-js": () => import("/opt/build/repo/src/pages/documentation/extensions.js" /* webpackChunkName: "component---src-pages-documentation-extensions-js" */),
  "component---src-pages-documentation-hourly-rates-js": () => import("/opt/build/repo/src/pages/documentation/hourly-rates.js" /* webpackChunkName: "component---src-pages-documentation-hourly-rates-js" */),
  "component---src-pages-documentation-index-js": () => import("/opt/build/repo/src/pages/documentation/index.js" /* webpackChunkName: "component---src-pages-documentation-index-js" */),
  "component---src-pages-documentation-projects-js": () => import("/opt/build/repo/src/pages/documentation/projects.js" /* webpackChunkName: "component---src-pages-documentation-projects-js" */),
  "component---src-pages-documentation-report-insights-js": () => import("/opt/build/repo/src/pages/documentation/report-insights.js" /* webpackChunkName: "component---src-pages-documentation-report-insights-js" */),
  "component---src-pages-documentation-reports-js": () => import("/opt/build/repo/src/pages/documentation/reports.js" /* webpackChunkName: "component---src-pages-documentation-reports-js" */),
  "component---src-pages-documentation-rounding-js": () => import("/opt/build/repo/src/pages/documentation/rounding.js" /* webpackChunkName: "component---src-pages-documentation-rounding-js" */),
  "component---src-pages-documentation-syncing-js": () => import("/opt/build/repo/src/pages/documentation/syncing.js" /* webpackChunkName: "component---src-pages-documentation-syncing-js" */),
  "component---src-pages-documentation-tracking-time-js": () => import("/opt/build/repo/src/pages/documentation/tracking-time.js" /* webpackChunkName: "component---src-pages-documentation-tracking-time-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-premium-index-js": () => import("/opt/build/repo/src/pages/premium/index.js" /* webpackChunkName: "component---src-pages-premium-index-js" */),
  "component---src-pages-privacy-js": () => import("/opt/build/repo/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-of-use-js": () => import("/opt/build/repo/src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

